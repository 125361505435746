import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const SEOEn = ({ seo = {} }) => {
  const query = graphql`
    query {
      allStrapiGlobal(filter: { locale: { eq: "en" } }) {
        nodes {
          siteName
          favicon {
            localFile {
              publicURL
            }
          }
          defaultSeo {
            metaTitle
            metaDescription
            shareImage {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `
  const { allStrapiGlobal } = useStaticQuery(query)
  const { defaultSeo, siteName, favicon } = allStrapiGlobal.nodes[0]

  // Merge default and page-specific SEO values
  const fullSeo = { ...defaultSeo, ...seo }

  const getMetaTags = () => {
    const tags = []

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: 'og:title',
          content: fullSeo.metaTitle,
        },
        {
          name: 'twitter:title',
          content: fullSeo.metaTitle,
        }
      )
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: 'description',
          content: fullSeo.metaDescription,
        },
        {
          property: 'og:description',
          content: fullSeo.metaDescription,
        },
        {
          name: 'twitter:description',
          content: fullSeo.metaDescription,
        }
      )
    }
    if (fullSeo.shareImage) {
      const imageUrl =
        (process.env.GATSBY_FRONT_URL || 'http://localhost:8000') +
        fullSeo.shareImage.localFile.publicURL
      tags.push(
        {
          name: 'image',
          content: imageUrl,
        },
        {
          property: 'og:image',
          content: imageUrl,
        },
        {
          name: 'twitter:image',
          content: imageUrl,
        }
      )
    }
    if (fullSeo.article) {
      tags.push({
        property: 'og:type',
        content: 'article',
      })
    }
    tags.push({ name: 'twitter:card', content: 'summary_large_image' })

    return tags
  }

  const metaTags = getMetaTags()

  return (
    <Helmet
      htmlAttributes={{
        lang: fullSeo.isEn ? 'en' : 'fr',
      }}
      title={fullSeo.title || fullSeo.metaTitle}
      titleTemplate={fullSeo.title ? `%s - ${siteName}` : siteName} // If no custom title, use the website name}
      meta={metaTags}
      link={[
        {
          rel: 'icon',
          href: favicon.localFile.publicURL,
        },
      ]}
    />
  )
}

SEOEn.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
}

SEOEn.defaultProps = {
  title: null,
  image: null,
}

export default SEOEn
