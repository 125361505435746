import React from 'react'
import { graphql } from 'gatsby'

import Layout from '~/components/layout'
import SEOEn from '~/components/seoEn'
import Section from '~/components/section'
import Gallery from '~/components/gallery'

const IndexPage = ({
  data: { mainDataFr, mainDataEn, allStrapiMeilleursLots },
}) => {
  const homeDataEn = mainDataEn.nodes[0].body
  const homeDataFr = mainDataFr.nodes[0].body
  const bestOfTitle = mainDataEn.nodes[0].best.title
  const bestOfData = allStrapiMeilleursLots.nodes

  const homeData = homeDataEn.map(
    ({ strapi_component, title, subtitle, content, id }, index) => ({
      background: homeDataFr[index].background,
      strapi_component,
      subtitle,
      title,
      content,
      id,
    })
  )

  const seo = {
    title: 'Home',
    metaDescription:
      'Gérard & Florian DUFRECHE. Auctioneers. Free and confidential appraisals of your art objects.',
  }
  return (
    <Layout isEn>
      <SEOEn seo={seo} />
      {homeData.map((el, index, arr) => {
        return (
          <div key={index}>
            {index === 1 && <Gallery title={bestOfTitle} list={bestOfData} />}
            <Section data={el} index={index} length={arr.length} />
          </div>
        )
      })}
    </Layout>
  )
}

export const query = graphql`
  query homeQueryEn {
    mainDataEn: allStrapiHome(filter: { locale: { eq: "en" } }) {
      nodes {
        body
        best {
          title
        }
      }
    }
    mainDataFr: allStrapiHome(filter: { locale: { eq: "fr-FR" } }) {
      nodes {
        body
      }
    }
    allStrapiMeilleursLots(
      sort: { order: DESC, fields: date }
      filter: { locale: { eq: "fr-FR" } }
    ) {
      nodes {
        photo {
          url
        }
        title
        price
      }
    }
  }
`

export default IndexPage
